@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 400;
  src: url(../assets/fonts/RobotoCondensed-Regular.ttf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoCondensed';
  font-style: italic;
  font-weight: 400;
  src: url(../assets/fonts/RobotoCondensed-Italic.ttf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoCondensed';
  font-weight: 700;
  src: url(../assets/fonts/RobotoCondensed-Bold.ttf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'RobotoCondensed';
  font-style: italic;
  font-weight: 700;
  src: url(../assets/fonts/RobotoCondensed-BoldItalic.ttf) format('opentype');
  font-display: swap;
}